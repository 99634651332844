import React from "react"

import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

export default function ContactIndex() {
    const data = useStaticQuery(graphql`
        query ContactQuery {
            site {
                siteMetadata {
                    email, telephone, address
                }
            }
        }
    `);

    let tel_link_number = data.site.siteMetadata.telephone.replace(/\s/g,'').replace(/0/,'+44');

    return (
        <>
            <Layout>
                <SEO title="Contact" />

                <PageTitle summary="Please call our friendly team Jacqui, Sharon and Victoria."
                           title="Contact"/>

                <div className={"body-wrap"}>
                    <p><span className="font-bold">Directors:</span> Gary Taylor and David Rose.</p>
                    <address className="not-italic">
                        <p><span className="font-bold">Tel:</span> <a href={'tel:' + tel_link_number}>{data.site.siteMetadata.telephone}</a></p>
                        <p><span className="font-bold">Email:</span> <a href={'mailto:' + data.site.siteMetadata.email + '?subject=General Enquiry'}>{data.site.siteMetadata.email}</a></p>
                        <p>
                            <span className="font-bold">Address:</span> {data.site.siteMetadata.address}<br/> By Appointment Only.
                        </p>
                    </address>
                </div>
            </Layout>
        </>
    )
}
